<template>
  <div class="referral-content">
    <UIPageHeader title="Referral Program" />

    <div class="referral-content__body">
      <AgentInfo :agent="agent" :isReferral="isReferral" @update="update"/>
      <ReferralsList v-if="agent" :agent="agent" @update="update"/>
    </div>
  </div>
</template>

<script>
import UIPageHeader from "@/components/ui/page-header/page-header.vue";
import AgentInfo from "@/components/shared/referral/agent/agentinfo.vue"
import ReferralsList from "@/components/shared/referral/agent/ReferralsList.vue"

export default {
  name: "ReferralContent",
  components: {
      UIPageHeader,
      AgentInfo,
      ReferralsList
  },
  props: {
    agent: {
      type: Object,
      required: true
    },
    referrals: {
      type: Array,
      required: true
    },
    isReferral: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    update() {
      this.$emit('update')
    }
  }
}
</script>

<style lang="scss">
.referral-content {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;

    .referral-content__body {
        background: $white;
        border-radius: 16px;
        padding: 28px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        grid-row-gap: 8px;
    }

    .referral-content__modal {
        @include modal;
    }
}

@media screen and (max-width: 768px) {
    .referral-content {
        grid-row-gap: 18px;

        .referral-content__body {
            padding: 13px 16px 26px 16px;
            // grid-row-gap: 14px;
        }
    }
}
</style>