import {getDate} from "@/utils/functions";

const CHARTS = [
    {borderColor: "#795EFC",backgroundColor: "#795EFC" },
    {borderColor: "#2850FF",backgroundColor: "#2850FF",},
    {borderColor: "#EBBE4C",backgroundColor: "#EBBE4C",},
    {borderColor: "#578AFF",backgroundColor: "#578AFF",},
    {borderColor: "#2CC7BD",backgroundColor: "#2CC7BD",}, //5
    {borderColor: "#96D5F2",backgroundColor: "#96D5F2",},
    {borderColor: "#FA9233",backgroundColor: "#FA9233",},
    {borderColor: "#46DB8B",backgroundColor: "#46DB8B",},
    {borderColor: "#89A8DC",backgroundColor: "#89A8DC",},
    {borderColor: "#005E5E",backgroundColor: "#005E5E",}, //10
    {borderColor: "#597591",backgroundColor: "#597591",},
    {borderColor: "#769e18",backgroundColor: "#769e18",},
    {borderColor: "#9c7c70",backgroundColor: "#9c7c70",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",}, //20
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",},
    {borderColor: "#f50505",backgroundColor: "#f50505",}, //25
]

export function getChartData(charts, labels) {
    return {
        labels,
        datasets: charts.map((chart) => {
            const {rates, pool_name, rating} = chart;

            return {
                label: pool_name,
                data: labels.map((label) => rates[label]),
                pointRadius: 0,
                hoverRadius: 6,
                borderWidth: 2,
                pointBorderColor: "#ffffff",
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
                pointHoverBorderColor: "#ffffff",
                tension: 0.4,
                borderColor: CHARTS[rating].borderColor,
                backgroundColor: CHARTS[rating].backgroundColor,
            };
        }),
    };
}

export function getChartOptions(labels, legend) {
    return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 12,
                    },
                    color: "#CCCDD2",
                    callback: function (value, index) {
                        return getDate(labels[index], "DD MMM");
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 12,
                    },
                    color: "#CCCDD2",
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
                titleAlign: "left",
                titleColor: "#FFFFFF",
                usePointStyle: true,
                pointStyle: "circle",
                boxWidth: 8,
                boxHeight: 8,
                boxPadding: 8,
                padding: {
                    top: 10,
                    bottom: 10,
                    right: 12,
                    left: 16,
                },
                callbacks: {
                    title: function (context) {
                        const [element] = context;

                        return getDate(element.label, "DD.MM.YYYY");
                    },
                    label: function ({dataset, raw}) {
                        return `${dataset.label}: ${raw.toFixed(2)} SAT`;
                    },
                },
            },
            legend: {
                display: legend,
                position: "bottom",
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    color: "#9E9FA8",
                    font: {
                        size: 12,
                        weight: 500,
                    },
                    usePointStyle: true,
                    pointStyle: "circle",
                },
            },
        },
    };
}
