<template>
    <div class="settings">
        <SettingsContent 
            :settings="settings" 
            :watchers="watchers" 
            :tags="tags" 
            :loading="loading"
            @updateWatchers="updateWatchers"
        />
    </div>
</template>

<script>
import SettingsContent from "@/components/shared/settings/content/content.vue";
import Api from "@/api/api";

const api = new Api();

export default {
    name: "Settings",
    components: {
        SettingsContent,
    },
    data() {
        return {
            loading: true,
            settings: {},
            watchers: [],
            tags: []
        }
    },
    created() {
        this.onFetchData();
        var helper = document.getElementsByClassName("b24-widget-button-wrapper")
        if (helper && helper[0] && helper[0].style) {
            helper[0].style.display = "none"
        }
    },
    methods: {
        async onFetchData() {
            this.loading  = true
            this.settings =  await api.settings()
            this.watchers =  await api.watcherLinks()
            this.tags =  await api.tags()
            this.loading  = false
        },
        async updateWatchers() {
            this.watchers =  await api.watcherLinks()
        }
    }
};
</script>
