<template>
    <div class="ui-card">
        <UICardHeader class="ui-card__header" :header="header" />

        <slot />

        <UICardFooter class="ui-card__footer" :footer="footer" />
    </div>
</template>

<script>
import UICardHeader from "@/components/ui/card/header.vue";
import UICardFooter from "@/components/ui/card/footer.vue";

export default {
    name: "UICard",
    components: {
        UICardHeader,
        UICardFooter,
    },
    props: {
        header: {
            type: Object,
            required: true,
        },
        footer: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.ui-card {
    background: $white;
    border-radius: 20px;
    padding: 48px 99px 40px 101px;
    display: flex;
    flex-direction: column;
    height: 700px;
    width: 540px;

    .ui-card__header {
        margin-bottom: 55px;
    }

    .ui-card__footer {
        margin-top: auto;
    }
}

@media screen and (max-width: 768px) {
    .ui-card {
        padding: 38px 20px 24px 20px;
        width: 100%;
        min-width: 359px;
        max-width: 359px;
        height: 674px;
    }
}
</style>
