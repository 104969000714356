<template>
    <div class="ui-toolbar-tabs">
        <router-link
            class="ui-toolbar-tabs__tab"
            :class="tab.class"
            v-for="tab in getTabs"
            :key="tab.id"
            :to="tab.to"
        >
            <div class="ui-toolbar-tabs__icon">
                <img class="ui-toolbar-tabs__img" :src="tab.icon" alt="icon" />
            </div>

            <p class="ui-toolbar-tabs__label">
                {{ tab.label }}
            </p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "UIToolbarTabs",
    computed: {
        getTabs() {
            return [
                {
                    id: 0,
                    label: "Statistics",
                    icon: require("@/assets/images/ui/toolbar/statistics.svg"),
                    to: "/",
                    class: "",
                },
                {
                    id: 1,
                    label: "Workers",
                    icon: require("@/assets/images/ui/toolbar/workers.svg"),
                    to: "/workers",
                    class: "",
                },
                {
                    id: 2,
                    label: "Wallet",
                    icon: require("@/assets/images/ui/toolbar/wallet.svg"),
                    to: "/wallet",
                    class: "",
                },
                {
                    id: 3,
                    label: "Rating",
                    icon: require("@/assets/images/ui/toolbar/rating.svg"),
                    to: "/rating",
                    class: "",
                },
                {
                    id: 3,
                    label: "Referral",
                    icon: require("@/assets/images/ui/toolbar/referral.svg"),
                    to: "/referral",
                    class: "",
                },
                {
                    id: 4,
                    label: "Settings",
                    icon: require("@/assets/images/ui/toolbar/settings.svg"),
                    to: "/settings",
                    class: "",
                },
            ];
        },
    },
};
</script>

<style lang="scss">
.ui-toolbar-tabs {
    display: flex;
    flex-direction: column;
    grid-row-gap: 44px;

    .ui-toolbar-tabs__tab {
        @include button-reset;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-row-gap: 6px;

        &:hover {
            .ui-toolbar-tabs__icon {
                background: rgba(31, 140, 215, 0.5);
            }

            .ui-toolbar-tabs__label {
                color: $white;
            }
        }

        &.router-link-exact-active {
            .ui-toolbar-tabs__icon {
                background: #1f8cd7;
            }

            .ui-toolbar-tabs__label {
                color: $white;
            }
        }

        &:disabled {
            opacity: $opacity;
            pointer-events: none;
        }
    }

    .ui-toolbar-tabs__tab--disabled {
        opacity: $opacity;
        pointer-events: none;
    }

    .ui-toolbar-tabs__icon {
        width: 48px;
        height: 48px;
        padding: 8px;
        border-radius: 14px;
        transition: $transition;
    }

    .ui-toolbar-tabs__label {
        @include golos-medium;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.5);
        transition: $transition;
    }
}

@media screen and (max-width: 768px) {
    .ui-toolbar-tabs {
        flex-direction: row;
        grid-column-gap: 14px;

        .ui-toolbar-tabs__icon {
            width: 50px;
            height: 52px;
            border-radius: 14px 14px 0 0;
            padding: 10px 12px 16px 12px;
        }

        .ui-toolbar-tabs__img {
            width: 26px;
        }

        .ui-toolbar-tabs__label {
            display: none;
        }
    }
}
</style>
