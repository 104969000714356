<template>
    <div class="login">
        <LoginContent />
    </div>
</template>

<script>
import LoginContent from "@/components/shared/login/content/content.vue";

export default {
    name: "Login",
    components: {
        LoginContent,
    },
    created() {
        var helper = document.getElementsByClassName("b24-widget-button-wrapper")
        if (helper && helper[0] && helper[0].style) {
            helper[0].style.display = "block"
        }
    }
};
</script>
