<template>
    <div class="wathcers-table">
        <WatchersTableHeader />

        <WatchersTableBody
            :watchers="watchers"
            :tags="tags"
            @remove="onRemove"
            @deleteWatcher="deleteWatcher"
        />
    </div>
</template>

<script>
import WatchersTableHeader from "@/components/shared/settings/watcher/table/header.vue";
import WatchersTableBody from "@/components/shared/settings/watcher/table/body.vue";


export default {
    name: "WatchersTable",
    components: {
        WatchersTableHeader,
        WatchersTableBody,
    },
    props: {
        watchers: {
            type: Array,
            default: () => [],
        },
        tags: {
            type: Array,
            required: true,
            default: []
        }
    },
    methods: {
        onRemove(watcher) {
            this.$emit("remove", watcher);
        },
        deleteWatcher(watcher) {
            this.$emit("deleteWatcher", watcher)
        }
    },
};
</script>
