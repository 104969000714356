<template>
    <div class="registration">
        <RegistrationContent />
    </div>
</template>

<script>
import RegistrationContent from "@/components/shared/registration/content/content.vue";

export default {
    name: "Registration",
    components: {
        RegistrationContent,
    },
    created() {
        var helper = document.getElementsByClassName("b24-widget-button-wrapper")
        if (helper && helper[0] && helper[0].style) {
            helper[0].style.display = "block"
        }
    }
};
</script>
