<template>
    <div class="ui-menu-profile">
        <div class="ui-menu-profile__group">
            <UISvg icon="user" />

            <p class="ui-menu-profile__label">
                {{ getEmail }}
            </p>
        </div>

        <UIMenuLanguage />
    </div>
</template>

<script>
import UISvg from "@/components/ui/svg/svg.vue";
import UIMenuLanguage from "@/components/ui/menu/language.vue";

export default {
    name: "UIMenuProfile",
    components: {
        UISvg,
        UIMenuLanguage,
    },
    computed: {
        getEmail() {
            return this.$store.state.user.email;
        },
    },
};
</script>

<style lang="scss">
.ui-menu-profile {
    display: flex;
    flex-direction: column;
    grid-row-gap: 32px;

    .ui-menu-profile__group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 16px;
        padding-bottom: 32px;
        border-bottom: 1px solid $gray;
    }

    .ui-menu-profile__label {
        @include golos-bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $txt-main;
    }
}
</style>
