<template>
    <div class="settings-password-code">
        <UIInputCode
            label="Security Verification"
            :comment="message"
            :fieldOne="codeForm.fieldOne"
            :fieldTwo="codeForm.fieldTwo"
            :fieldThree="codeForm.fieldThree"
            :fieldFour="codeForm.fieldFour"
            :fieldFive="codeForm.fieldFive"
            :fieldSix="codeForm.fieldSix"
            @field="onChangeField"
        />

        <UIButton label="Change Password" view="main" @clicked="onChange" />
    </div>
</template>

<script>
import UIInputCode from "@/components/ui/input/code.vue";
import UIButton from "@/components/ui/button/button.vue";
import Api from "@/api/api";

const api = new Api();

export default {
    name: "SettingsPasswordCode",
    components: {
        UIInputCode,
        UIButton,
    },
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            message: "Enter the 6 digit code from 2FA",
            codeForm: {
                fieldOne: "",
                fieldTwo: "",
                fieldThree: "",
                fieldFour: "",
                fieldFive: "",
                fieldSix: "",
            },
        };
    },
    methods: {
        onChangeField({key, value}) {
            this.codeForm[key] = value;
        },

        async onChange() {

            const body = {
                newPassword: this.form.newPassword,
                verifyPassword: this.form.confirmationPassword,
                verifyCode: this.codeForm.fieldOne + this.codeForm.fieldTwo + this.codeForm.fieldThree + this.codeForm.fieldFour + this.codeForm.fieldFive + this.codeForm.fieldSix
            }
            try {
                this.loading = true;

                await api
                    .settingsChangePassword(body)
                    .then(({status, message}) => {
                        if (status === RESPONSE_STATUSES.error) {
                            this.message = message;
                        } else if (status === RESPONSE_STATUSES.success) {
                            this.$emit("close");
                        }

                        this.loading = false;
                    });
            } catch (e) {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.settings-password-code {
    display: flex;
    flex-direction: column;
    grid-row-gap: 128px;
    padding-top: 65px;
}
</style>
