<template>
    <div class="rating-table-cell-pool">
        {{ cell.pool_name }}
        <a
            class="rating-table-cell-pool__link"
            v-if="cell.poolUrl"
            :href="cell.poolUrl"
            target="_blank"
        >
            <UISvg icon="share" />
        </a>
    </div>
</template>

<script>
import UISvg from "@/components/ui/svg/svg.vue";

export default {
    name: "RatingTableCellPool",
    components: {
        UISvg,
    },
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.rating-table-cell-pool {
    padding: 0 30px;
    display: flex;
    align-items: center;

    .rating-table-cell-pool__link {
        margin-left: 10px;
        fill: rgba(65, 92, 107, 0.2);

        &:hover {
            fill: $main-hover;
        }
    }
}
.rating-table-row__cell--user {
    color: green;
}
</style>
