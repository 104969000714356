<template>
    <div class="rating-table-cell-rating">
        <p class="rating-table-cell-rating__label" v-if="!cell.shortName">
            {{ cell.poolId }} 
        </p>
    </div>
</template>

<script>

export default {
    name: "RatingTableCellRating",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.rating-table-cell-rating {
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rating-table-cell-rating__label {
        font-size: 14px;
        line-height: 17px;
        color: $txt-main;
    }
}
</style>
