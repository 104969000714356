<template>
    <div class="restore-password">
        <RestorePasswordContent />
    </div>
</template>

<script>
import RestorePasswordContent from "@/components/shared/restore-pasword/content/content.vue";

export default {
    name: "RestorePassword",
    components: {
        RestorePasswordContent,
    },
    created() {
        var helper = document.getElementsByClassName("b24-widget-button-wrapper")
        if (helper && helper[0] && helper[0].style) {
            helper[0].style.display = "block"
        }
    }
};
</script>
