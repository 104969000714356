<template>
    <div class="settings-watcher">
        <h2 class="settings-watcher__title">
            Watcher Links
            <UIButton
                class="watcher-add-button"
                label="ADD"
                view="main-outline"
                size="small"
                @clicked="addWatcher"
            />
        </h2>
        <WatchersTable
            :watchers="watchers"
            :tags="tags"
            @deleteWatcher="deleteWatcher"
        />
    </div>
</template>

<script>
import WatchersTable from "@/components/shared/settings/watcher/table.vue"; 
import UIButton from "@/components/ui/button/button.vue";

export default {
    name: "SettingsWatcher",
    components: {
        WatchersTable,
        UIButton
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        watchers: {
            type: Array,
            required: true,
            defaul: []
        },
        tags: {
            type: Array,
            required: true,
            default: []
        }
    },
    methods: {
        deleteWatcher(watcher) {
            this.$emit("deleteWatcher", watcher)
        },
        addWatcher() {
            console.log("addWatcher")
            this.$emit('showAddModal')
        }
    }
};
</script>

<style lang="scss">
.settings-watcher  {
    background: $white;
    border-radius: 16px;
    padding: 24px 40px 48px 40px;

    .settings-watcher__title {
        font-size: 28px;
        line-height: 34px;
        color: $txt-main;
        margin-bottom: 12px;
        display: flex;
    }
}

.watcher-add-button {
    margin-left: auto;
}

@media screen and (max-width: 1300px) {
    .settings-main {
        display: flex;
        flex-direction: column;
        grid-row-gap: 29px;
    }
}

@media screen and (max-width: 768px) {
    .settings-main {
        padding: 21px 12px 32px 12px;
    }
}
</style>