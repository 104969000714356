<template>
  <div class="watcher-table-body">
    <div class="watcher-table-row" v-for="(watcher, index) in watchers" :key="watcher.id">
      <div class="watcher-table-cell" v-for="cell in getCells(watcher)" :key="cell.id">
        <p class="watcher-table-cell__label" v-if="cell.id == 0">
          {{ index + 1 }}
        </p>
        <p class="watcher-table-cell__label" v-if="cell.id == 1">
          <a :href="getWl(watcher)" target="blank">{{ cell.value }}</a>
        </p>
        <p class="watcher-table-cell__label" v-if="cell.id == 2">
          <div class="mr-1" v-for="tagId of cell.value" :key="tagId">{{getTagName(tagId)}}</div>
        </p>
        <p class="watcher-table-cell__label" v-if="cell.id > 2 && cell.id < 7">
          <UICheckbox
            :value="cell.value"
            :small="true"
            :disabled="true"
          />
        </p>
        <p v-if="cell.id == 7">
          <button
            class="watcher-table-row-remove__button"
            @click="onRemove(watcher)"
          >
              <img
                  src="@/assets/images/shared/workers/table/trash.svg"
                  alt="trash"
              />
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import UICheckbox from "@/components/ui/checkbox/checkbox.vue";
import {WL} from "@/utils/constants.js"; 

export default {
  name: "WatchersTableBody",
  components: {
    UICheckbox
  },
  props: {
    watchers: {
      type: Array,
      default: () => [],
    },
    tags: {
        type: Array,
        required: true,
        default: []
    }
  },
  methods: {
    getCells(watcher) {
      return [
        {
          id: 0,
          value: '#'
        },
        {
          id: 1,
          value: watcher.name
        },
        {
          id: 2,
          value: watcher.tagIds
        },
        {
          id: 3,
          value: watcher.worker
        },
        {
          id: 4,
          value: watcher.transaction
        },
        {
          id: 5,
          value: watcher.balance
        },
        {
          id: 6,
          value: watcher.hashrate
        },
        {
          id: 7
        },
      ]
    },
    onRemove(watcher) {
      this.$emit("deleteWatcher", watcher)
    },
    getTagName(id) {
      const tag = this.tags?.find(t => t.id == id)
      return  tag ? '#' + this.tags?.find(t => t.id == id)?.name : "DEL"
    },
    getWl(watcher) {
      return "//" + WL + '/?code=' + watcher.id
    }
  }
}
</script>

<style lang="scss">
.watcher-table-row {
    padding: 4px 0;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    border-radius: 10px;

    &:nth-child(2n) {
        background: $bg-gray-light;
    }

    .watcher-table-row__cell--adaptive {
        display: none;
    }
}

.watcher-table-cell {
    padding: 0 10px;

    .watcher-table-cell__label {
        font-size: 14px;
        line-height: 17px;
        color: $txt-main;
    }
}

.watcher-table-row-remove__button {
  @include button-reset;
}

</style>