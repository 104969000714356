<template>
    <div class="settings-content">
        <UIPageHeader class="settings-content__header" title="Settings" />

        <SettingsMain
            class="settings-content__main"
            :loading="loading"
            :settings="settings"
            @password="onChangePassword"
            @email="onChangeEmail"
            @loading="onChangeLoading"
        />

        <SettingsWatcher 
            class="settings-content__main"
            :watchers="watchers"
            :tags="tags"
            :loading="loading"
            @showAddModal="onShowAddModal"
            @deleteWatcher="deleteWatcher"
        />

        <SettingsOther
            id="other"
            :loading="loading"
            :settings="settings"
            @loading="onChangeLoading"
        />

        <transition name="modal">
            <SettingsPassword
                class="settings-content__password"
                v-if="getPasswordIsShow"
                @close="onClosePassword"
            />
        </transition>
        <transition name="modal">
            <SettingsAddWatcher
                class="settings-content__password"
                v-if="showAddWatcherModal"
                :tags="tags"
                @close="onCloseAddWatcherModal"
                @addWatcher="onAddWatcher"
            />
        </transition>
    </div>
</template>

<script>
import UIPageHeader from "@/components/ui/page-header/page-header.vue";
import SettingsMain from "@/components/shared/settings/main/main.vue";
import SettingsOther from "@/components/shared/settings/other/other.vue";
import SettingsWatcher from "@/components/shared/settings/watcher/watcher.vue";
import SettingsPassword from "@/components/shared/settings/password/password.vue";
import SettingsAddWatcher from "@/components/shared/settings/watcher/addModal.vue";
import Api from "@/api/api";

const api = new Api();

export default {
    name: "SettingsContent",
    components: {
        UIPageHeader,
        SettingsMain,
        SettingsOther,
        SettingsPassword,
        SettingsWatcher,
        SettingsAddWatcher
    },
    props: {
        settings: {
            type: Object,
            required: true
        },
        watchers: {
            type: Array,
            required: true,
            default: []
        },
        tags: {
            type: Array,
            required: true,
            default: []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            password: false,
            email: false,
            showAddWatcherModal: false
        };
    },
    mounted() {
        this.onScroll();
    },
    computed: {
        getPasswordIsShow() {
            return this.password;
        },
    },
    methods: {
        onScroll() {
            const {swap} = this.$route.query;

            if (swap) {
                const targetElement = document.getElementById("other");

                targetElement.scrollIntoView({behavior: "smooth"});
            }
        },

        onChangeLoading(loading) {
            this.loading = loading;
        },

        onChangePassword() {
            this.password = true;
        },

        onClosePassword() {
            this.password = false;
        },

        onChangeEmail() {
            this.email = true;
        },

        onShowAddModal() {
            this.showAddWatcherModal = true
        },

        onCloseAddWatcherModal() {
            this.showAddWatcherModal = false
        },

        onAddWatcher(watcher) {
            console.log(watcher)
            api.addwatcher(watcher).finally(() => {
                this.$emit("updateWatchers")
            })
        },

        deleteWatcher(watcher) {
            api.deletewatcher({id: watcher.id}).finally(() => {
                this.$emit("updateWatchers")
            })
        }
    },
};
</script>

<style lang="scss">
.settings-content {
    .settings-content__header {
        margin-bottom: 22px;
    }

    .settings-content__main {
        margin-bottom: 16px;
    }

    .settings-content__password {
        @include modal;
    }
}
</style>
