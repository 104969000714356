<template>
    <div class="rating-table-row">
        <component
            class="rating-table-row__cell"
            v-for="cell in getCells"
            :key="cell.id"
            :cell="cell"
            :is="cell.component"
            :class="cell.class"
            :active="active"
            @add="onAddChart"
            @remove="onRemoveChart"
        />
    </div>
</template>

<script>
import RatingTableCellRating from "@/components/shared/rating/table/cell-rating.vue";
import RatingTableCellPool from "@/components/shared/rating/table/cell-pool.vue";
import RatingTableCell from "@/components/shared/rating/table/cell.vue";
import RatingTableCellChart from "@/components/shared/rating/table/cell-chart.vue";
import RatingTableCellRatingPool from "@/components/shared/rating/table/cell-rating-pool.vue";
import RatingTableCellPayrate from "@/components/shared/rating/table/cell-payrate.vue";

const COMPONENTS = {
    default: RatingTableCell,
    rating: RatingTableCellRating,
    pool: RatingTableCellPool,
    chart: RatingTableCellChart,
    ratingPool: RatingTableCellRatingPool,
    payrate: RatingTableCellPayrate,
};

const DEFAULT_INDEX = 1;
// const FIRST_ROW_INDEX = 0;
// const MAX_PERCENT = 100;
// const DEFAULT_FIXED_VALUE = 2;
const DEFAULT_AVR_RATE_FIXED_VALUE = 2;

export default {
    name: "RatingTableRow",
    components: {
        RatingTableCellRating,
        RatingTableCell,
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            default: 0,
        },
        active: {
            type: Boolean,
            default: false,
        },
        rating: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        getCells() {
            const {pool_url, pool_name, percent, avrRate, is_user} = this.row;

            const id = this.index + DEFAULT_INDEX;

            return [
                {
                    id: 0,
                    component: COMPONENTS.rating,
                    poolId: id + ". " + pool_name.toUpperCase(),
                    class: "rating-table-row__cell--rating-pool",
                },
                {
                    id: 1,
                    component: COMPONENTS.rating,
                    poolId: id,
                    class: "rating-table-row__cell--id",
                },
                {
                    id: 2,
                    component: COMPONENTS.pool,
                    pool_name,
                    poolUrl: pool_url,
                    class: "rating-table-row__cell--pool" + (is_user && " rating-table-row__cell--user"),
                },
                {
                    id: 3,
                    label: avrRate.toFixed(DEFAULT_AVR_RATE_FIXED_VALUE),
                    component: COMPONENTS.default,
                    class: "rating-table-row__cell--default",
                },
                {
                    id: 4,
                    label: percent,
                    component: COMPONENTS.default,
                    class: "rating-table-row__cell--percent",
                },
                {
                    id: 5,
                    component: COMPONENTS.chart,
                },
            ];
        },
    },
    methods: {
        onAddChart() {
            this.$emit("add", this.row);
        },

        onRemoveChart() {
            this.$emit("remove", this.row);
        },
    },
};
</script>

<style lang="scss">
.rating-table-row {
    padding: 6px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-radius: 10px;

    &:nth-child(2n) {
        background: $bg-gray-light;
    }

    .rating-table-row__cell--adaptive {
        display: none;
    }

    .rating-table-row__cell--rating-pool {
        display: none;
    }
}

// @media screen and (max-width: 1440px) {
//     .rating-table-row {
//         grid-template-columns: repeat(5, 1fr);
//         padding: 11px 0;
//         height: 60px;

//         .rating-table-row__cell--adaptive {
//             display: flex;
//         }

//         .rating-table-row__cell--payrate {
//             justify-content: center;
//         }
//     }
// }

@media screen and (max-width: 1024px) {
    .rating-table-row {
        grid-template-columns: 1fr 1fr 70px;

        .rating-table-row__cell--rating-pool {
            display: flex;
        }

        .rating-table-row__cell--id {
            display: none;
        }

        .rating-table-row__cell--percent {
            display: none;
        }

        .rating-table-row__cell--pool {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .rating-table-row {
        grid-template-columns: 1fr 1fr 70px;
        border-radius: 0;
    }
}
</style>
