<template>
    <div class="watchers-table-header">
        <div class="watchers-table-column" v-for="column in getColumns" :key="column.id">
            <p class="watchers-table-column__label">
                {{ column.label }}
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: "RatingTableHeader",
    props: {
        sort: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getColumns() {
            return [
                {
                    id: 0,
                    label: "#",
                },
                {
                    id: 1,
                    label: "Name",
                },
                {
                    id: 2,
                    label: "Tags",
                },
                {
                    id: 3,
                    label: "Workers",
                },
                {
                    id: 4,
                    label: "Transactions",
                },
                {
                    id: 5,
                    label: "Balance",
                },
                {
                    id: 6,
                    label: "Hashrate",
                },
                {
                    id: 7,
                    label: "DEL",
                },
            ];
        },
    },
};
</script>

<style lang="scss">
.watchers-table-header {
    background: $bg-gray;
    border-radius: 10px;
    padding: 5px 0;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}
.watchers-table-column {
    padding: 0 10px;
    display: flex;
    align-items: center;

    .watchers-table-column__label {
        @include golos-semi-bold;
        font-size: 12px;
        line-height: 17px;
        text-transform: uppercase;
        color: $txt-main;
        transition: $transition;
    }
}

</style>
