<template>
    <div class="settings-main-notifications">
        <h2 class="settings-main-notifications__title">Notifications</h2>

        <div class="settings-main-notifications__groups">
            <SettingsMainOperations
                :loading="loading"
                :form="form"
                :telegram="telegram"
                @checkbox="onChangeCheckbox"
            />

            <SettingsMainStatuses
                :loading="loading"
                :form="form"
                :telegram="telegram"
                @checkbox="onChangeCheckbox"
            />

            <UIButton
                class="settings-other-address__save"
                label="Save"
                view="main-outline"
                size="small"
                :disabled="!isChange"
                @clicked="onSave"
            />
        </div>
    </div>
</template>

<script>
import SettingsMainOperations from "@/components/shared/settings/main/operations.vue";
import SettingsMainStatuses from "@/components/shared/settings/main/statuses.vue";
import UIButton from "@/components/ui/button/button.vue";
import Api from "@/api/api";

const api = new Api();

export const CHECKBOXES = {
    payout: {
        email: false,
        telegram: false,
        sms: false
    },
    income: {
        email: false,
        telegram: false,
        sms: false
    },
    withdrawal: {
        email: false,
        telegram: false,
        sms: false
    },
    connectingWorker: {
        email: false,
        telegram: false,
        sms: false
    },
    onlineOfflineWorker: {
        email: false,
        telegram: false,
        sms: false
    },
    dropHashrate: {
        email: false,
        telegram: false,
        sms: false
    }
};

export const NOTIFICATION = {
    EMAIL: "EMAIL",
    TELEGRAM: "TELEGRAM",
    SMS: "SMS"
};


export default {
    name: "SettingsMainNotifications",
    components: {
        SettingsMainOperations,
        SettingsMainStatuses,
        UIButton
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        settings: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isChange: false,
            form: {
                payout: {
                    email: false,
                    telegram: false,
                    sms: false
                },
                income: {
                    email: false,
                    telegram: false,
                    sms: false
                },
                withdrawal: {
                    email: false,
                    telegram: false,
                    sms: false
                },
                connectingWorker: {
                    email: false,
                    telegram: false,
                    sms: false
                },
                onlineOfflineWorker: {
                    email: false,
                    telegram: false,
                    sms: false
                },
                dropHashrate: {
                    email: false,
                    telegram: false,
                    sms: false
                }
            },
        };
    },
    computed: {
        telegram() {
            return this.settings && this.settings.telegram
        }
    },
    watch: {
        settings(value) {
            console.log('settings(value)', value)
            this.form = {
                payout: {
                    email: value?.notificationPayout?.includes(NOTIFICATION.EMAIL),
                    telegram: value?.notificationPayout?.includes(NOTIFICATION.TELEGRAM),
                    sms: value?.notificationPayout?.includes(NOTIFICATION.SMS)
                },
                income: {
                    email: value?.notificationIncome?.includes(NOTIFICATION.EMAIL),
                    telegram: value?.notificationIncome?.includes(NOTIFICATION.TELEGRAM),
                    sms: value?.notificationIncome?.includes(NOTIFICATION.SMS)
                },
                withdrawal: {
                    email: value?.notificationWithdraw?.includes(NOTIFICATION.EMAIL),
                    telegram: value?.notificationWithdraw?.includes(NOTIFICATION.TELEGRAM),
                    sms: value?.notificationWithdraw?.includes(NOTIFICATION.SMS)
                },
                connectingWorker: {
                    email: value?.notificationNewDevice?.includes(NOTIFICATION.EMAIL),
                    telegram: value?.notificationNewDevice?.includes(NOTIFICATION.TELEGRAM),
                    sms: value?.notificationNewDevice?.includes(NOTIFICATION.SMS)
                },
                onlineOfflineWorker: {
                    email: value?.notificationOnlineOffline?.includes(NOTIFICATION.EMAIL),
                    telegram: value?.notificationOnlineOffline?.includes(NOTIFICATION.TELEGRAM),
                    sms: value?.notificationOnlineOffline?.includes(NOTIFICATION.SMS)
                },
                dropHashrate: {
                    email: value?.notificationHashrate?.includes(NOTIFICATION.EMAIL),
                    telegram: value?.notificationHashrate?.includes(NOTIFICATION.TELEGRAM),
                    sms: value?.notificationHashrate?.includes(NOTIFICATION.SMS)
                }
            }
        }
    },
    methods: {
        onChangeCheckbox({key, value}) {
            this.form[key[0]][key[1]] = value;
            this.isChange = true
        },

        onSave() {
            const body = {
                notificationPayout: [],
                notificationIncome: [],
                notificationNewDevice: [],
                notificationOnlineOffline: [],
                notificationHashrate: [],
                notificationWithdraw: []
            }
            if (this.form.payout.email) body.notificationPayout.push(NOTIFICATION.EMAIL)
            if (this.form.payout.telegram) body.notificationPayout.push(NOTIFICATION.TELEGRAM)
            if (this.form.payout.sms) body.notificationPayout.push(NOTIFICATION.SMS)
            if (this.form.income.email) body.notificationIncome.push(NOTIFICATION.EMAIL)
            if (this.form.income.telegram) body.notificationIncome.push(NOTIFICATION.TELEGRAM)
            if (this.form.income.sms) body.notificationIncome.push(NOTIFICATION.SMS)
            if (this.form.withdrawal.email) body.notificationWithdraw.push(NOTIFICATION.EMAIL)
            if (this.form.withdrawal.telegram) body.notificationWithdraw.push(NOTIFICATION.TELEGRAM)
            if (this.form.withdrawal.sms) body.notificationWithdraw.push(NOTIFICATION.SMS)
            if (this.form.connectingWorker.email) body.notificationNewDevice.push(NOTIFICATION.EMAIL)
            if (this.form.connectingWorker.telegram) body.notificationNewDevice.push(NOTIFICATION.TELEGRAM)
            if (this.form.connectingWorker.sms) body.notificationNewDevice.push(NOTIFICATION.SMS)
            if (this.form.onlineOfflineWorker.email) body.notificationOnlineOffline.push(NOTIFICATION.EMAIL)
            if (this.form.onlineOfflineWorker.telegram) body.notificationOnlineOffline.push(NOTIFICATION.TELEGRAM)
            if (this.form.onlineOfflineWorker.sms) body.notificationOnlineOffline.push(NOTIFICATION.SMS)
            if (this.form.dropHashrate.email) body.notificationHashrate.push(NOTIFICATION.EMAIL)
            if (this.form.dropHashrate.telegram) body.notificationHashrate.push(NOTIFICATION.TELEGRAM)
            if (this.form.dropHashrate.sms) body.notificationHashrate.push(NOTIFICATION.SMS)
            api.settingsSaveNotification(body).then(() => {
                this.isChange = false
            })
        }
    },
};
</script>

<style lang="scss">
.settings-main-notifications {
    .settings-main-notifications__title {
        font-size: 28px;
        line-height: 34px;
        color: $txt-main;
        margin-bottom: 32px;
    }

    .settings-main-notifications__groups {
        display: flex;
        flex-direction: column;
        grid-row-gap: 16px;
    }
}

@media screen and (max-width: 768px) {
    .settings-main-notifications {
        .settings-main-notifications__title {
            font-size: 24px;
            line-height: 29px;
            margin: 0 0 24px 4px;
        }
    }
}
</style>
