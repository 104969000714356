<template>
  <h2 class="referral-list-title">Referrals</h2>
  <div class="referral-list-content referral-list-header">
    <span class="referral-item-content"><b>Name</b></span>
    <span class="referral-item-content"><b>Email</b></span>
    <span class="referral-item-content"><b>Hashrate</b></span>
    <span class="referral-item-content" v-if="agent.permissionIncome"><b>Income fee, %</b></span>
    <span class="referral-item-content" v-if="agent.permissionBonus"><b>Bonus fee, %</b></span>
    <span class="referral-item-content" v-if="agent.permissionReferral"><b>Referral bonus, %</b></span>
    <span></span>
  </div>
  <div v-for="(referral, i) of referrals" :key="referral.id" class="referral-list-content ">
    <span v-if="editId === referral.id" class="referral-item-content">
      <span class="name-edit">
        <span class="referral-item-content" style="margin-right: 5px">{{i + 1}}.</span>
        <UIInput
            :placeholder="referral.name"
            :value="newName"
            :full="false"
            @changed="onChangeName"
            :small="true"
        />
      </span>
    </span>
    <span v-else class="referral-item-content">
      <span class="referral-item-content">{{i + 1}}. <b>{{referral.name}}</b></span>
    </span>
    <span class="referral-item-content">{{referral.email}}</span>
    <span class="referral-item-content">{{referral.dayHashrate}}</span>
    <span v-if="agent.permissionIncome" class="referral-item-content">{{referral.feeIncomePercent}} %</span>
    <span v-if="agent.permissionBonus" class="referral-item-content">{{referral.feeBonusPercent}} %</span>
    <span v-if="agent.permissionReferral" class="referral-item-content">{{referral.referralPercent}} %</span>
    <span v-if="editId !== referral.id">      
      <UIButton
        label="edit"
        view="green"
        :full="false"
        :uppercase="true"
        @clicked="edit(referral)"
        :size="'small'"
      />
    </span>
    <span v-else>
      <UIButton
        label="save"
        view="green"
        :full="false"
        :uppercase="true"
        @clicked="save(referral)"
        :size="'small'"
      />
    </span>
  </div>
</template>

<script>
import UIButton from "@/components/ui/button/button.vue"
import UIInput from "@/components/ui/input/input.vue"
import Api from "@/api/api"
const api = new Api()

export default {
  name: "ReferralsList",
  components: {
    UIButton,
    UIInput
  },
  props: {
    agent: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      editId: null,
      newName: '',
      referrals: []
    }
  },
  async created() {
      this.getReferrals()
  },
  methods: {
    edit(referral) {
      this.editId = referral.id
    },
    save(referral) {
      if (this.newName && referral.name != this.newName) {
        referral.name = this.newName
        api.updateReferral(referral).finally(() => {
          this.$emit('update')
        })
      }
      this.editId = null
    },
    onChangeName(value) {
      this.newName = value
    },
    async getReferrals() {
      await api.referrals().then((referrals) => {
          this.referrals = referrals;
      })
    }
  }
}
</script>

<style lang="scss">
.referral-list-content {
    display: flex;
    flex-direction: row;
    grid-row-gap: 16px;
    padding-right: 0px;
    padding-left: 20px;
    border-radius: 10px;
}
.referral-list-content:nth-child(2n) {
  background: gainsboro;
}
.referral-list-header {
  padding-top: 12px;
  padding-bottom: 12px;
}
.referral-item-content {
  margin-right: auto;
  align-self: center;
}
.referral-list-title {
    font-size: 28px;
    line-height: 34px;
    color: $txt-main;
    margin-bottom: 14px;
}
.name-edit {
  display: flex;
  flex-direction: row;
}
.referral-name__edit {
  @include button-reset;
  margin-left: 4px;
}
.referral-name__edit-icon {
  fill: $txt-light;
}

@media screen and (max-width: 768px) {
  .referral-list-content {
    display: block;
    grid-row-gap: 16px;
    padding: 5px;
    border-radius: 10px;
  }
  .referral-item-content {
    display: block;
  }
}
</style>