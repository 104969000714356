<template>
    <div>
        <ReferralContent v-if="!loading" :isReferral="isReferral" :agent="agent" :referrals="referrals" @update="update" />
    </div>
</template>

<script>
import ReferralContent from "@/components/shared/referral/content/content.vue";
import Api from "@/api/api";

const api = new Api();

export default {
    name: "Referral",
    components: {
        ReferralContent,
    },
    data() {
      return {
            loading: true,
            agent: null,
            isReferral: false,
            ranks: [],
            referrals: []
      };
    },
    async created() {
        this.update()
        var helper = document.getElementsByClassName("b24-widget-button-wrapper")
        if (helper && helper[0] && helper[0].style) {
            helper[0].style.display = "none"
        }
    },
    methods: {
        update() {
            this.loading = true
            this.getAgent().finally(() => {
                this.loading = false
            })
        },

        async getAgent() {
            await api.agent().then((agent) => {
                if (agent != null) {
                    this.agent = agent
                    this.getReferrals()
                }
            })
        },

        async getRank() {
            await api.rank().then((ranks) => {
                this.ranks = ranks
            })
        },

        async getIsReferral() {
            await api.isReferral().then((isReferral) => {
                this.isReferral = isReferral
            })
        },

        becomeAgent() {
          console.log("becomeAgent")
        }
    },
};
</script>