<template>
  <!-- <div v-if="!isReferral && !showInputCode" class="agent-code">
    <UIButton 
        label="become an referral"
        view="main"
        :full="true"
        :uppercase="true"
        @clicked="becomeReferral"
    />
    <span class="agent-code-content-text">Если у Вас есть пригласительный код, нажмите "become an referral"</span>
  </div>
  <div v-else class="agent-code">
    <span class="agent-code-content-text">Вы участник реферальной программы</span>
  </div>
  <div v-if="!isReferral && showInputCode" class="agent-code">
    <div class="agent-code-content">
      <UIInput
          placeholder="input agent code"
          :value="code"
          :full="true"
          @changed="onChangeCode"
      />
      <UIButton
          label="SEND"
          view="main"
          :full="false"
          :uppercase="true"
          @clicked="sendCode"
      />
    </div>
  </div> -->
  <div v-if="!agent">
    <!-- <p class="agent-code-content-info">Описание реферальной программы (на английском)</p> -->
    <UIButton 
        label="become an agent"
        view="main"
        :full="true"
        :uppercase="true"
        @clicked="becomeAgent"
    />
    <!-- <p class="agent-code-content-text">Если Вы хотите стать Агентом, нажмите "become an agent" </p> -->
  </div>
  <div v-else  class="agent-content">
    <h2 class="agent-info-title">Agent Info</h2>
    <div class="agent-rank-content">
      <span class="agent-rank-content__item">Rank:</span>
      <b class="agent-rank-content__item">{{agent.rank}}</b>
      <UIInfo
        v-show="true"
        position="left"
      >
        <div class="agent-rank-info">
          Bonus
        </div>
      </UIInfo>
    </div>
    <p class="agent-info-content agent-nvite-code">
      <span style="margin-right: 10px; align-self: center;">Invite referral code: <b>{{agent.inviteCode}}</b></span>
      <UICopy :text="agent.inviteCode" /></p>
    <!-- <div class="agent-info-content">Created: {{convert(agent.created)}}</div> -->
    <!-- <div class="invite-div">
      <UIButton
        label="Invite"
        view="green"
        :full="false"
        :uppercase="true"
        @clicked="invite"
        :disabled="true"
      />
    </div> -->
  </div>
</template>

<script>
import Api from "@/api/api";
const api = new Api();

import UIInfo from "@/components/ui/info/info.vue";
import UIButton from "@/components/ui/button/button.vue";
import UIInput from "@/components/ui/input/input.vue"
import { convertLocalTimeDateArrToString } from "@/utils/functions"
import UICopy from "@/components/ui/copy/copy.vue";

export default {
  name: "AgentInfo",
  components: {
    UIButton,
    UIInput,
    UICopy,
    UIInfo
  },
  props: {
    agent: {
      type: Object,
      required: true
    },
    isReferral: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showInputCode: false,
      code: null
    }
  },
  methods: {
    becomeAgent() {
      api.becomeAgent().finally(() => {
        this.$emit('update')
      })
    },
    convert(arr) {
      return convertLocalTimeDateArrToString(arr)
    },
    // becomeReferral() {
    //   this.showInputCode = true
    // },
    onChangeCode(value) {
      this.code = value
    },
    sendCode() {
      api.inviteReferral({ code: this.code }).then(data => {
      })
    }
  },
}
</script>

<style lang="scss">
.invite-div {
  margin-top: 10px;
}
.agent-code {
  margin-bottom: 32px;
}
.agent-code-content {
  display: flex;
  flex-direction: row;
  grid-row-gap: 16px;
}
.agent-content {
  margin-bottom: 32px;
}
.agent-rank-content {
  display: flex;
  flex-direction: row;
  grid-row-gap: 16px;
}
.agent-rank-content__item {
  margin-right: 10px;
}
.agent-rank-info {
    font-size: 12px;
    line-height: 20px;
    color: $white;
}
.agent-info-content {
  margin-bottom: 10px;
}
.agent-info-title {
    font-size: 28px;
    line-height: 34px;
    color: $txt-main;
    margin-bottom: 14px;
}
.agent-nvite-code {
  display: flex;
  flex-direction: row;
}
.agent-code-content-info {
    font-size: 16px;
    line-height: 16px;
    color: $txt-light;
    margin: 20px 0px;
}
.agent-code-content-text {
    margin-top: 5px;
    font-size: 14px;
    line-height: 14px;
    color: $txt-light;
    text-align: center;
}
</style>