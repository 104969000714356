import {createRouter, createWebHistory} from "vue-router";

import Dashboard from "@/views/dashboard/dashboard.vue";
import Workers from "@/views/workers/workers.vue";
import Wallet from "@/views/wallet/wallet.vue";
import Rating from "@/views/rating/rating.vue";
import Settings from "@/views/settings/settings.vue";
import Login from "@/views/login/login.vue";
import Registration from "@/views/registration/registration.vue";
import Referral from "@/views/referral/referral.vue"
import RestorePassword from "@/views/restore-password/restore-password.vue";

import {getCookie} from "@/utils/functions";

const BACKGROUNDS = {
    default: "#ECF6FC",
    main: "#36a1ea",
};

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
        meta: {
            layout: "default",
            auth: true,
            background: BACKGROUNDS.default,
        },
    },
    {
        path: "/workers",
        name: "workers",
        component: Workers,
        meta: {
            layout: "default",
            auth: true,
            background: BACKGROUNDS.default,
        },
    },
    {
        path: "/wallet",
        name: "wallet",
        component: Wallet,
        meta: {
            layout: "default",
            auth: true,
            background: BACKGROUNDS.default,
        },
    },
    {
        path: "/rating",
        name: "rating",
        component: Rating,
        meta: {
            layout: "default",
            auth: true,
            background: BACKGROUNDS.default,
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            layout: "default",
            auth: true,
            background: BACKGROUNDS.default,
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            layout: "authorization",
            background: BACKGROUNDS.main,
        },
    },
    {
        path: "/registration",
        name: "registration",
        component: Registration,
        meta: {
            layout: "authorization",
            background: BACKGROUNDS.main,
        },
    },
    {
        path: "/restore-password",
        name: "restore-password",
        component: RestorePassword,
        meta: {
            layout: "authorization",
            background: BACKGROUNDS.main,
        },
    },
    {
        path: "/referral",
        name: "referral",
        component: Referral,
        meta: {
            layout: "default",
            auth: true,
            background: BACKGROUNDS.default,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.auth)) {
        if (!getLogged()) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    } else if (
        (to.name === "login" || to.name === "registration") &&
        getLogged()
    ) {
        next({
            path: "/",
        });
    } else {
        next();
    }
});

function getLogged() {
    return getCookie("accessToken") && getCookie("refreshToken");
}

export default router;
