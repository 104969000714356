<template>
    <div class="settings-main-operations">
        <h4 class="settings-main-operations__title">
            <span>Financial Operations</span>
            <div class="settings-main-operations__checkbox">
                <span>MAIL</span>
                <span style="margin-left: 10px;  margin-right: 5px;">TG</span>
            </div>
        </h4>

        <div class="settings-main-operations__checkboxes">
            <div class="settings-main-operations__checkbox">
                <p class="settings-main-operations__checkbox-label">
                    Mining Payout
                </p>

                <div class="settings-main-operations__checkbox">
                    <UICheckbox
                        :value="form.payout.email"
                        :small="true"
                        :disabled="true"
                        @changed="onChangeCheckbox(['payout', 'email'], $event);"
                    />
                    <span style="margin-left: 5px;" >
                        <UICheckbox
                            :value="form.payout.telegram"
                            :small="true"
                            :disabled="getDisabled"
                            @changed="onChangeCheckbox(['payout', 'telegram'], $event);"
                        />
                    </span>
                </div>
            </div>

            <div class="settings-main-operations__checkbox">
                <p class="settings-main-operations__checkbox-label">
                    Mining Rewards
                </p>

                <div class="settings-main-operations__checkbox">
                    <UICheckbox
                        :value="form.income.email"
                        :small="true"
                        :disabled="true"
                        @changed="onChangeCheckbox(['income', 'email'], $event);"
                    />
                    <span style="margin-left: 5px;" >
                        <UICheckbox
                            :value="form.income.telegram"
                            :small="true"
                            :disabled="getDisabled"
                            @changed="onChangeCheckbox(['income', 'telegram'], $event);"
                        />
                    </span>
                </div>
            </div>

            <div class="settings-main-operations__checkbox">
                <p class="settings-main-operations__checkbox-label">
                    Wallet Withdrawal
                </p>

                <div class="settings-main-operations__checkbox">
                    <UICheckbox
                        :value="form.withdrawal.email"
                        :small="true"
                        :disabled="true"
                        @changed="onChangeCheckbox(['withdrawal', 'email'], $event);"
                    />
                    <span style="margin-left: 5px;" >
                        <UICheckbox
                            :value="form.withdrawal.telegram"
                            :small="true"
                            :disabled="getDisabled"
                            @changed="onChangeCheckbox(['withdrawal', 'telegram'], $event);"
                        />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UICheckbox from "@/components/ui/checkbox/checkbox.vue";

import {CHECKBOXES} from "@/components/shared/settings/main/notifications.vue";

export default {
    name: "SettingsMainOperations",
    components: {
        UICheckbox,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        form: {
            type: Object,
            required: true,
        },
        telegram: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        getDisabled() {
            return this.loading;
        },

        getCheckboxes() {
            return CHECKBOXES;
        },
    },
    methods: {
        onChangeCheckbox(key, value) {
            console.log(key, value)
            this.$emit("checkbox", {key, value});
        },
    },
};
</script>

<style lang="scss">
.settings-main-operations {
    .settings-main-operations__title {
        font-size: 14px;
        line-height: 17px;
        color: $txt-main;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
    }

    .settings-main-operations__checkboxes {
        display: flex;
        flex-direction: column;
        grid-row-gap: 6px;
    }

    .settings-main-operations__checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
    }

    .settings-main-operations__checkbox-label {
        font-size: 14px;
        line-height: 17px;
        color: $txt-main;
    }
}
</style>
