<template>
    <div class="rating-table-header">
        <component
            class="rating-table-header__column"
            v-for="column in getColumns"
            :class="column.class"
            :key="column.id"
            :is="column.component"
            :column="column"
            :sort="sort"
            @sort="onSort"
        />
    </div>
</template>

<script>
import RatingTableColumn from "@/components/shared/rating/table/column.vue";
import RatingTableColumnRatingPool from "@/components/shared/rating/table/column-rating-pool.vue";
import RatingTableColumnPayrate from "@/components/shared/rating/table/column-payrate.vue";

const COMPONENTS = {
    default: RatingTableColumn,
    ratingPool: RatingTableColumnRatingPool,
    payrate: RatingTableColumnPayrate,
    poolName: RatingTableColumn
};

export default {
    name: "RatingTableHeader",
    components: {
        RatingTableColumn,
        RatingTableColumnRatingPool,
        RatingTableColumnPayrate,
    },
    props: {
        sort: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getColumns() {
            return [
                {
                    id: 0,
                    label: "Rating",
                    labelSmall: "Rating / Pool",
                    info: "The rating is based on actually working equipment connected through anonymous accounts.",
                    class: "rating-table-header__column--rating",
                    component: COMPONENTS.default,
                },
                {
                    id: 1,
                    label: "Pool",
                    class: "rating-table-header__column--pool",
                    component: COMPONENTS.default,
                },
                {
                    id: 2,
                    label: "Payrate, SAT",
                    labelSmall: "payrate",
                    sort: true,
                    key: "avrRate",
                    class: "rating-table-header__column--rate",
                    component: COMPONENTS.default,
                },
                {
                    id: 3,
                    label: "%",
                    class: "rating-table-header__column--percent",
                    component: COMPONENTS.default,
                },
                {
                    id: 4,
                    label: "Add to chart",
                    labelSmall: "Chart",
                    class: "rating-table-header__column--chart",
                    component: COMPONENTS.default,
                },
            ];
        },
    },
    methods: {
        onSort(column) {
            this.$emit("sort", column);
        },
    },
};
</script>

<style lang="scss">
.rating-table-header {
    background: $bg-gray;
    border-radius: 10px;
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    .rating-table-header__column--adaptive {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .rating-table-header {
        grid-template-columns: 1fr 1fr 70px;

        .rating-table-header__column--percent {
            display: none;
        }

        .rating-table-header__column--pool {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .rating-table-header {
        grid-template-columns: 1fr 1fr 70px;
        border-radius: 0;
        padding: 13px 0;
    }
}
</style>
