<template>
    <div class="settings-other-payouts">
        <h2 class="settings-other-payouts__title">Payouts</h2>

        <SettingsOtherPayout
            class="settings-other-payouts__payout"
            :loading="loading"
            :enableAutoPayout="enableAutoPayout"
            @changeAutoPayout="onChangeAutoPayout"
        />

        <SettingsOtherAddress
            class="settings-other-payouts__address"
            :wallet="wallet"
            :disabled="!enableAutoPayout"
            :loading="loading"
            @save="saveWallet"
            @loading="onChangeLoading"
        />

        <SettingsOtherCurrency 
            :loading="loading" 
            @loading="onChangeLoading" 
            :disabled="!enableAutoPayout" 
            :min="min"
            @onChangeMinPayout="onChangeMinPayout"
        />

        <div class="settings-other-payouts__save">
            <p class="settings-other-payout__label">{{ result }}</p>
            <UIButton
                label="Save"
                view="main-outline"
                size="small"
                :disabled="true"
                @clicked="onSave"
            />
        </div>

    </div>
</template>

<script>
import SettingsOtherAddress from "@/components/shared/settings/other/address.vue";
import SettingsOtherPayout from "@/components/shared/settings/other/payout.vue";
import SettingsOtherCurrency from "@/components/shared/settings/other/currency.vue";
import UIButton from "@/components/ui/button/button.vue";
import Api from "@/api/api";

const api = new Api();

export default {
    name: "SettingsOtherPayouts",
    components: {
        SettingsOtherAddress,
        SettingsOtherPayout,
        SettingsOtherCurrency,
        UIButton
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        settings: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            enableAutoPayout: false,
            wallet: null,
            min: 0
        }
    },
    computed: {
        result() {
            if (this.enableAutoPayout) {
                return "Enable Auto Payout minmal " + this.min + " BTC to " + this.wallet + " address"
            } else {
                return "Disable Auto Payout"
            }
        }
    },
    watch: {
        settings(value) {
            if (value) {
                this.enableAutoPayout = value.enableAutoWithdrawal
                this.min = value.minAutoWithdrawal
                if (value.enableAutoWithdrawal) {
                    this.getWallet()
                }
            }
        },
    },
    methods: {
        onChangeLoading(loading) {
            this.$emit("loading", loading);
        },

        onChangeAutoPayout(value) {
            this.enableAutoPayout = value
            if (value && !this.wallet) {
                this.getWallet()
            }
        },

        onChangeMinPayout(value) {
            this.min = value
        },

        async getWallet() {
            await api.settingsWallet().then(data => {
                if (data.length) this.wallet = data[0].address
            })
        },

        async saveWallet(params) {
            await api.settingsAddWallet(params).then(data => {
                this.getWallet()
            })
        },

        async onSave() {
            console.log(this.min)
            const params = {
                enableAutoWithdrawal: this.enableAutoPayout,
                minAutoWithdrawal: this.min,
                scheduler: "NONE"
            }
            await api.settingsAutoPayout(params)
        }
    },
};
</script>

<style lang="scss">
.settings-other-payouts {
    .settings-other-payouts__title {
        @include golos-semi-bold;
        font-size: 28px;
        line-height: 34px;
        color: $txt-main;
        margin-bottom: 32px;
    }

    .settings-other-payouts__address {
        margin-bottom: 24px;
    }

    .settings-other-payouts__payout {
        margin-bottom: 16px;
    }

    .settings-other-payouts__save {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        grid-row-gap: 16px;
    }

    .settings-other-payout__label {
        font-size: 14px;
        line-height: 17px;
        color: $txt-main;
    }
}

@media screen and (max-width: 768px) {
    .settings-other-payouts {
        .settings-other-payouts__title {
            font-size: 24px;
            line-height: 29px;
            margin: 0 0 24px 4px;
        }
    }
}
</style>
