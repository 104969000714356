<template>
    <div class="settings-other-address">
        <UIInput
            class="settings-other-address__field"
            label="To BTC Payment Address:"
            placeholder="Enter BTC wallet address"
            :full="true"
            :value="walletAddress"
            :disabled="getDisabled"
            @changed="onChangeWalletAddress"
        />

        <UIButton
            class="settings-other-address__save"
            label="Change"
            view="main-outline"
            size="small"
            :disabled="getDisabledChange"
            @clicked="onSave"
        />
    </div>
</template>

<script>
import UIInput from "@/components/ui/input/input.vue";
import UIButton from "@/components/ui/button/button.vue";

export default {
    name: "SettingsOtherAddress",
    components: {
        UIInput,
        UIButton,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        wallet: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            walletAddress: "",
        };
    },
    computed: {
        getDisabled() {
            return this.loading || this.disabled;
        },

        getDisabledChange() {
            return this.loading || this.disabled || this.walletAddress === this.wallet;
        },
    },
    watch: {
        wallet(value) {
            console.log("wallet", value)
            this.walletAddress = value
        }
    },
    methods: {
        onChangeWalletAddress(walletAddress) {
            this.walletAddress = walletAddress;
        },

        onSave() {
            if (this.walletAddress !== this.wallet) {
                const params = {
                    name: "BTC",
                    address: this.walletAddress,
                    addressTag: "",
                    network: "BTC",
                    coin: "BTC",
                    isMain: true
                }
                this.$emit("save", params);
            }
        },
    },
};
</script>

<style lang="scss">
.settings-other-address {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .settings-other-address__field {
        max-width: 340px;
    }

    .settings-other-address__save {
        margin-bottom: 3px;
    }
}

@media screen and (max-width: 768px) {
    .settings-other-address {
        .settings-other-address__field {
            max-width: 230px;
        }
    }
}
</style>
