<template>
    <UIModal class="settings-watcher-add" @close="onClose">
        <div class="settings-watcher-add__content">
            <h2 class="settings-watcher-add__title">Add Watcher</h2>
            <h5>Name</h5>
            <UIInput
                placeholder="Input WL Name"
                :value="watcher.name"
                :full="true"
                @changed="changeName"
            />
            <h5>Permission</h5>
            <div class="watcher-permission">
              <div class="flex">
                workers:
                <UICheckbox
                  class="float-right"
                  :value="watcher.worker"
                  :small="true"
                  @changed="watcher.worker = !watcher.worker"
                />
              </div>
              <div class="flex">
                balance:
                <UICheckbox
                  class="float-right"
                  :value="watcher.balance"
                  :small="true"
                  @changed="watcher.balance = !watcher.balance"
                />
              </div>
              <div class="flex">
                transactions:
                <UICheckbox
                  class="float-right"
                  :value="watcher.transaction"
                  :small="true"
                  @changed="watcher.transaction = !watcher.transaction"
                />
              </div>
              <div class="flex">
                login:
                <UICheckbox
                  class="float-right"
                  :value="watcher.login"
                  :small="true"
                  @changed="watcher.login = !watcher.login"
                />
              </div>
            </div>
            <h5>Tags</h5>
            <WorkersTag :tags="tags" @selectTag="selectTag"/>
            <UIButton
                label="ADD"
                view="main"
                :full="true"
                :disabled="!getDisabled"
                @clicked="add"
            />
        </div>
    </UIModal>
</template>

<script>
import UIModal from "@/components/ui/modal/modal.vue";
import UICheckbox from "@/components/ui/checkbox/checkbox.vue";
import UIInput from "@/components/ui/input/input.vue";
import UIButton from "@/components/ui/button/button.vue";
import WorkersTag from "@/components/shared/workers/header/tags.vue";

export default {
    name: "SettingsPassword",
    components: {
        UIModal,
        UICheckbox,
        UIInput,
        UIButton,
        WorkersTag
    },
    props: {
      tags: {
        type: Array,
        required: true,
        default: []
      }
    },
    data() {
        return {
          watcher: {
            name: "",
            worker: false,
            hashrate: false,
            balance: false,
            transaction: false,
            login: false,
            tags: []
          }
        };
    },
    computed: {
      getDisabled() {
        return !!this.watcher.name 
      }
    },
    methods: {
      onClose() {
        this.$emit("close")
      },
      add() {
        this.watcher.tags = this.tags.filter(t => t.show).map(t => t.id)
        this.$emit("addWatcher", this.watcher)
        this.onClose()
      },
      changeName(name) {
        this.watcher.name = name
      },
      selectTag() {
        //console.log(this.tags)
      }
    },
};
</script>

<style lang="scss">
.settings-watcher-add {
    .settings-watcher-add__content {
        width: 640px;
        padding: 48px 150px;
        display: flex;
        flex-direction: column;
        grid-row-gap: 12px;
    }

    .settings-watcher-add__title {
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        color: $txt-main;
    }

    .watcher-permission {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
    }
}

.flex {
  display: flex;
  padding: 10px;
  align-items: center;
}

.float-right {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
    .settings-watcher-add {
        .settings-watcher-add__content {
            width: 360px;
            padding: 32px 0 24px 0;
            grid-row-gap: 8px;
            margin: 0 auto;
        }

        .settings-watcher-add__title {
            font-size: 24px;
            line-height: 29px;
        }
    }
}
</style>
